import axios from 'axios';
import { csrfToken } from 'utils/document';
import { IPartnerIntegrationInfo } from './createPartnerIntegration';

const updatePartnerIntegration = async (partnerIntegrationInfo: Partial<IPartnerIntegrationInfo>) => {
  const {
    type,
    pmcID,
    name,
    propertyOwnerID,
    base_uri,
    username,
    password,
    server_name,
    deposit_charge_code,
    deposit_payment_code,
    enabled,
    auto_invite_enabled,
    ledger_sync_enabled,
    native_enrollment_enabled,
    show_sdi_coverage_amount,
    health_check_enabled,
    yardi_charge_codes,
    ledger_sync_create_charge,
    ledger_sync_tagged_transactions,
    write_to_deposit_memo,
    configuring_ils_gc,
    uuid,
    ils_username,
    ils_entity,
    ils_password,
    allow_writes,
    single_family_only,
    enabled_partner_rule_set,
    enabled_custom_rule_set,
    update_enabled,
    cash_deposit_push_enabled,
    invite_resident_statuses,
    invite_resident_credit_statuses,
    invite_immediately
  } = partnerIntegrationInfo;

  const token = csrfToken();

  const response = await axios.put<IPartnerIntegrationInfo>(
    `/v2/integrations/partners/${uuid}`,
    {
      integration: {
        type: type,
        pmc_id: pmcID,
        name: name,
        property_owner_id: propertyOwnerID,
        base_uri: base_uri,
        username: username,
        password: password,
        server_name: server_name,
        deposit_charge_code: deposit_charge_code,
        deposit_payment_code: deposit_payment_code,
        enabled: enabled,
        ledger_sync_enabled: ledger_sync_enabled,
        native_enrollment_enabled,
        show_sdi_coverage_amount,
        health_check_enabled: health_check_enabled,
        charge_codes_attributes: yardi_charge_codes,
        ledger_sync_create_charge: ledger_sync_create_charge,
        ledger_sync_tagged_transactions: ledger_sync_tagged_transactions,
        write_to_deposit_memo: write_to_deposit_memo,
        auto_invite_enabled: auto_invite_enabled,
        configuring_ils_gc: configuring_ils_gc,
        ils_username: ils_username,
        ils_entity: ils_entity,
        ils_password: ils_password,
        allow_writes: allow_writes,
        single_family_only: single_family_only,
        enabled_partner_rule_set: enabled_partner_rule_set,
        enabled_custom_rule_set: enabled_custom_rule_set,
        update_enabled: update_enabled,
        cash_deposit_push_enabled: cash_deposit_push_enabled,
        invite_resident_statuses: invite_resident_statuses,
        invite_resident_credit_statuses: invite_resident_credit_statuses,
        invite_immediately: invite_immediately
      }
    },
    {
      headers: {
        'X-CSRF-Token': token
      }
    }
  );
  return response;
};

export default updatePartnerIntegration;
