 /** @jsx jsx */
import { jsx } from '@emotion/core';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';

import { LemonadeInterestedParty } from 'entities/lemonade';
import { StyledContent } from '../PropertyOwners/Styled';

const Subtitle = styled.h4([
  FONTS.h5,
  {
    paddingBottom: 4,
    marginTop: 16,
    borderBottom: `1px solid ${PALETTE.neutral12}`
  }
]);

const DisplayField = styled.p(
  {
    padding: '12px 0',
    borderBottom: `1px solid ${PALETTE.neutral12}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'default',
    ...FONTS.p1Medium,
    textAlign: 'right',
    color: '#212526',
  }
);

interface IProps {
  interestedParty?: LemonadeInterestedParty;
}

const InterestedParty = (props: IProps) => {
  const { interestedParty } = props;

  if (!interestedParty) return null;

  return (
    <div>
      <Subtitle>Interested Party</Subtitle>
      <StyledContent>
        Lemonade will include the Interested Party on communication emails including creation, renewal, and cancellation of a renter's policy. The Interested Party will also be listed on the renter's policy document. The address will never be used to send the Interested party physical mail. This will apply to all properties belonging to this Property Owner.
      </StyledContent>
      <DisplayField>
        {interestedParty.name} ({interestedParty.email}, {interestedParty.address})
      </DisplayField>
    </div>
  )
}

export default InterestedParty;
