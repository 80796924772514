import updatePartnerIntegration from 'api/v2/updatePartnerIntegration';
import { PARTNER_INTEGRATION_KEY } from 'api/v2/usePartnerIntegration';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { IRealPageIntegration, IYardiIntegration, IYardiIntegrationPartnerableAttributes } from '../interfaces';
import useToast, { TOAST_STATUS } from '../toast/use-toast';

export const useIntegrationDetails = (integration: IYardiIntegration | IRealPageIntegration) => {
  const [dataPullEnabled, setDataPullEnabled] = useState(integration.enabled);
  const [autoInviteEnabled, setAutoInviteEnabled] = useState(integration.auto_invite_enabled);
  const [immediateConditionalInvitesEnabled, setImmediateConditionalInvitesEnabled] = useState(
    (integration as IRealPageIntegration).invite_immediately
  );
  const [policyPushEnabled, setPolicyPushEnabled] = useState(integration.update_enabled);
  const [cashDepositPushEnabled, setCashDepositPushEnabled] = useState(integration.cash_deposit_push_enabled);
  const [ledgerSyncEnabled, setLedgerSyncEnabled] = useState(integration.ledger_sync_enabled);
  const [ledgerSyncCreateCharge, setLedgerSyncCreateCharge] = useState(integration.ledger_sync_create_charge);
  const [ledgerSyncTaggedTransactions, setLedgerSyncTaggedTransactions] = useState(integration.ledger_sync_tagged_transactions);
  const [writeToDepositMemo, setWriteToDepositMemo] = useState(integration.write_to_deposit_memo);
  const [depositChargeCode, setDepositChargeCode] = useState<string>(integration.deposit_charge_code ?? '');

  let yardiAttributes: IYardiIntegrationPartnerableAttributes | undefined;
  if (integration.type == 'yardi')
    yardiAttributes = (integration as IYardiIntegration).integration_partnerable_attributes;
  const [nativeEnrollmentEnabled, setNativeEnrollmentEnabled] = useState(integration?.native_enrollment_enabled ?? false);
  const [showSdiCoverageAmount, setShowSdiCoverageAmount] = useState(integration?.show_sdi_coverage_amount ?? false);
  const [healthCheckEnabled, setHealthCheckEnabled] = useState(integration?.health_check_enabled ?? false);
  const [yardiChargeCodes, setYardiChargeCodes] = useState(yardiAttributes?.charge_codes_attributes ?? []);

  const [showAutoInviteWarning, setShowAutoInviteWarning] = useState<boolean>(false);
  const { mutate: updatePartnerMutation, isLoading: isUpdatePartnerLoading } = useMutation(
    updatePartnerIntegration,
    {}
  );
  const { addToast } = useToast();

  const disablePolicyAndCashDepositPushSwitch = !dataPullEnabled;
  const queryClient = useQueryClient();
  const updateDataPull = () => {
    setDataPullEnabled(!dataPullEnabled);
    updatePartnerMutation(
      { enabled: !integration?.enabled, uuid: integration?.uuid, type: integration?.type },
      GetToasts('Data-pull', dataPullEnabled)
    );
  };

  const invalidatePartnerIntegrationQueries = () => {
    queryClient.invalidateQueries([PARTNER_INTEGRATION_KEY, integration?.uuid]);
  };

  const updateAutoInvite = () => {
    setShowAutoInviteWarning(false);
    setAutoInviteEnabled(!autoInviteEnabled);
    updatePartnerMutation(
      {
        auto_invite_enabled: !autoInviteEnabled,
        uuid: integration?.uuid,
        type: integration?.type
      },
      {
        onSuccess: invalidatePartnerIntegrationQueries
      }
    );
    GetToasts('Auto invites', autoInviteEnabled);
  };

  const updateImmediateConditionalInvites = () => {
    setImmediateConditionalInvitesEnabled(!immediateConditionalInvitesEnabled);
    updatePartnerMutation(
      { invite_immediately: !immediateConditionalInvitesEnabled, uuid: integration?.uuid, type: integration?.type },
      GetToasts('Immediate Conditional Invites', immediateConditionalInvitesEnabled)
    );
  };

  const shouldToggleAutoInvite = () => {
    if (autoInviteEnabled) {
      updateAutoInvite();
      return;
    }
    setShowAutoInviteWarning(true);
  };

  const closeWarningModal = () => setShowAutoInviteWarning(false);

  const updatePolicyPush = () => {
    setPolicyPushEnabled(!policyPushEnabled);
    updatePartnerMutation(
      {
        update_enabled: !policyPushEnabled,
        uuid: integration?.uuid,
        type: integration?.type
      },
      { onSuccess: invalidatePartnerIntegrationQueries }
    );
    GetToasts('Policy push', policyPushEnabled);
  };

  const updateCashDepositPush = () => {
    setCashDepositPushEnabled(!cashDepositPushEnabled);
    updatePartnerMutation(
      {
        cash_deposit_push_enabled: !cashDepositPushEnabled,
        uuid: integration?.uuid,
        type: integration?.type
      },
      { onSuccess: invalidatePartnerIntegrationQueries }
    );
    GetToasts('Cash deposit push', cashDepositPushEnabled);
  };

  const updateLedgerSync = () => {
    setLedgerSyncEnabled(!ledgerSyncEnabled);
    updatePartnerMutation(
      {
        ledger_sync_enabled: !ledgerSyncEnabled,
        uuid: integration?.uuid,
        type: integration?.type
      },
      { onSuccess: invalidatePartnerIntegrationQueries }
    );
    GetToasts('Ledger Sync', ledgerSyncEnabled);
  };

  const updateLedgerSyncCreateCharge = () => {
    setLedgerSyncCreateCharge(!ledgerSyncCreateCharge);
    updatePartnerMutation(
      {
        ledger_sync_create_charge: !ledgerSyncCreateCharge,
        uuid: integration?.uuid,
        type: integration?.type
      },
      { onSuccess: invalidatePartnerIntegrationQueries }
    );
    GetToasts('Ledger Sync Create Charge', ledgerSyncCreateCharge);
  };

  const updateLedgerSyncTaggedTransactions = () => {
    setLedgerSyncTaggedTransactions(!ledgerSyncTaggedTransactions);
    updatePartnerMutation(
      {
        ledger_sync_tagged_transactions: !ledgerSyncTaggedTransactions,
        uuid: integration?.uuid,
        type: integration?.type
      },
      { onSuccess: invalidatePartnerIntegrationQueries }
    );
    GetToasts('Add "RH" to Ledger Description?', ledgerSyncTaggedTransactions);
  };

  const updateWriteToDepositMemo = () => {
    setWriteToDepositMemo(!writeToDepositMemo);
    updatePartnerMutation(
      {
        write_to_deposit_memo: !writeToDepositMemo,
        uuid: integration?.uuid,
        type: integration?.type
      },
      { onSuccess: invalidatePartnerIntegrationQueries }
    );
    GetToasts('Write To Deposit Memo?', writeToDepositMemo);
  };

  const updateDepositChargeCode = () => {
    updatePartnerMutation(
      {
        deposit_charge_code: depositChargeCode,
        uuid: integration?.uuid,
        type: integration?.type
      },
      { onSuccess: invalidatePartnerIntegrationQueries }
    );
  };

  const updateNativeEnrollment = () => {
    setNativeEnrollmentEnabled(!nativeEnrollmentEnabled);
    updatePartnerMutation(
      {
        native_enrollment_enabled: !nativeEnrollmentEnabled,
        uuid: integration?.uuid,
        type: integration?.type
      },
      { onSuccess: invalidatePartnerIntegrationQueries }
    );
    GetToasts('Ledger Sync', ledgerSyncEnabled);
  };

  const updateHealthCheck = () => {
    setHealthCheckEnabled(!healthCheckEnabled);
    updatePartnerMutation(
      {
        health_check_enabled: !healthCheckEnabled,
        uuid: integration?.uuid,
        type: integration?.type
      },
      { onSuccess: invalidatePartnerIntegrationQueries }
    );
  };

  const updateShowSdiCoverageAmount = () => {
    setShowSdiCoverageAmount(!showSdiCoverageAmount);
    updatePartnerMutation(
      {
        show_sdi_coverage_amount: !showSdiCoverageAmount,
        uuid: integration?.uuid,
        type: integration?.type
      },
      { onSuccess: invalidatePartnerIntegrationQueries }
    );
  };

  const updateYardiChargeCode = (id: number, collected: boolean) => {
    const chargeCode = yardiChargeCodes.find(chargeCode => chargeCode.id === id);
    if (!chargeCode)
      return;

    chargeCode.collected = collected;
    setYardiChargeCodes(yardiChargeCodes);

    updatePartnerMutation(
      {
        yardi_charge_codes: yardiChargeCodes,
        uuid: integration?.uuid,
        type: integration?.type
      },
      { onSuccess: invalidatePartnerIntegrationQueries }
    );
  };

  const GetToasts = (type: string, state: boolean) => {
    return {
      onSuccess: () => {
        addToast(`${type} successfully ${state ? 'disabled' : 'enabled'}`, 'success', TOAST_STATUS.SUCCESS);
      },
      onError: () => {
        addToast(`Something went wrong`, 'error', TOAST_STATUS.ERROR);
      }
    };
  };

  return {
    dataPullEnabled,
    setDataPullEnabled,
    autoInviteEnabled,
    setAutoInviteEnabled,
    immediateConditionalInvitesEnabled,
    setImmediateConditionalInvitesEnabled,
    policyPushEnabled,
    cashDepositPushEnabled,
    setPolicyPushEnabled,
    updateDataPull,
    updateAutoInvite,
    updateImmediateConditionalInvites,
    updatePolicyPush,
    updateCashDepositPush,
    disablePolicyAndCashDepositPushSwitch,
    isUpdatePartnerLoading,
    shouldToggleAutoInvite,
    showAutoInviteWarning,
    closeWarningModal,
    updateLedgerSync,
    updateNativeEnrollment,
    nativeEnrollmentEnabled,
    updateShowSdiCoverageAmount,
    showSdiCoverageAmount,
    updateHealthCheck,
    healthCheckEnabled,
    updateLedgerSyncCreateCharge,
    ledgerSyncEnabled,
    ledgerSyncCreateCharge,
    ledgerSyncTaggedTransactions,
    updateLedgerSyncTaggedTransactions,
    writeToDepositMemo,
    updateWriteToDepositMemo,
    depositChargeCode,
    setDepositChargeCode,
    updateDepositChargeCode,
    updateYardiChargeCode,
  };
};

export default useIntegrationDetails;
