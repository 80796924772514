/** @jsx jsx */
import { jsx } from '@emotion/core';

import React, { useState } from 'react';
import { TextInput as TextInputBase, FONTS } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';

import { LemonadeInterestedParty } from 'entities/lemonade';
import { StyledContent } from '../PropertyOwners/Styled';

const TextInput = styled(TextInputBase)({
  width: '100%'
});
const ToggleButton = styled.button({
  background: 'transparent',
  border: 'none',
  fontSize: 16,
  lineHeight: '28px',
  color: '#6318CE',
  textDecoration: 'underline',
  fontFamily: 'MaisonNeueLight',
  cursor: 'pointer'
});

interface IProps {
  value?: LemonadeInterestedParty;
  errors: { [key: string]: string; };
  onChange: (value?: LemonadeInterestedParty) => void;
  ownerInterestedParty?: LemonadeInterestedParty;
}

const ManageInterestedParty = (props: IProps) => {
  const { errors } = props;
  const interestedParty = props.value;
  const ownerInterestedParty = props.ownerInterestedParty;

  const [isOpen, setIsOpen] = useState<boolean>(!!interestedParty);

  const handleInputChange = (event, key: string) => {
    const newInterestedParty = Object.assign({}, interestedParty, { [key]: event.target.value });

    props.onChange(newInterestedParty);
  }

  const onOpen = () => {
    if (interestedParty) {
      const { _destroy, ...newInsterestedParty } = interestedParty
      props.onChange(newInsterestedParty);
    }

    setIsOpen(true);
  }

  const onRemove = () => {
    setIsOpen(false);

    if (interestedParty?.id) {
      props.onChange(Object.assign({}, interestedParty, { _destroy: '1' }));
    } else {
      props.onChange();
    }
  }

  const renderFields = () => (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    }}>
      <div style={{
        display: 'flex',
        gap: '24px',
        flexDirection: 'row',
      }}>
        <TextInput
          id="lemonade_interested_party_attributes_name"
          label="Name"
          data-cy="lemonadeInterestedPartyAttributesName"
          value={interestedParty?.name || ''}
          onChange={(event) => handleInputChange(event, 'name')}
          error={!!errors.name}
          subtext={errors.name ?? ''}
        />
        <TextInput
          id="lemonade_interested_party_attributes_address"
          label="Address"
          data-cy="lemonadeInterestedPartyAttributesAddress"
          value={interestedParty?.address || ''}
          onChange={(event) => handleInputChange(event, 'address')}
          error={!!errors.address}
          subtext={errors.address ?? ''}
        />
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
      }}>
        <TextInput
          type="email"
          id="lemonade_interested_party_attributes_email"
          label="Email"
          data-cy="lemonadeInterestedPartyAttributesEmail"
          value={interestedParty?.email || ''}
          onChange={(event) => handleInputChange(event, 'email')}
          error={!!errors.email}
          subtext={errors.email ?? ''}
        />
      </div>
    </div>
  );

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginTop: '32px',
      textAlign: 'left'
    }} id='lemonade_interested_party'>
      <div css={[
        FONTS.h5,
        {
          width: "100%",
          lineHeight: 'normal',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: 8,
          alignItems: 'flex-end'
        }
      ]}>
        <header>Add Interested Party</header>
        <ToggleButton
          id="toggle-interested-party"
          onClick={isOpen ? onRemove : onOpen}
          title={isOpen ? "Remove interested party" : "Add new interested party"}
        >
          { isOpen ? "Remove" : "Add new" }
        </ToggleButton>
      </div>


      <StyledContent>
        This Interested Party will take priority over the Property Owner's Interested Party
        {ownerInterestedParty?.email ? `: ${ownerInterestedParty.email}` : ''}
      </StyledContent>

      { isOpen && renderFields() }
    </div>
  )
}

export default ManageInterestedParty;
